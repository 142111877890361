import React from 'react';
import FontAwesomeIcon from '../../../FontAwesomeIcon';
import TextField from '../_Types/TextField';

const MobileField = ({ data, updateField, errorFields, onSubmit = () => {} }) => {
  return <fieldset>
    <label htmlFor="mobile" className="text-primary mb-1">
      <FontAwesomeIcon icon="mobile-alt" className="me-2"/>
      Mobile Number
    </label>
    <TextField
      id={'mobile'}
      type={'tel'}
      placeholder={'Enter your mobile number'}
      value={data.mobile ?? ''}
      onChange={updateField}
      required={false}
      autoComplete={'off'}
      error={0 < errorFields.filter(field => 'mobile' === field.key).length}
      onSubmit={onSubmit}
    />
    {0 < errorFields.filter(field => 'mobile' === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => 'mobile' === field.key)[0].message}
    </div> : ''}
  </fieldset>;
};

export default MobileField;
