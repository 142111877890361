import Request from '../system/Request';

const Cart = {
  get: (cartId, isAuthenticated) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/cart/${cartId}/get`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, isAuthenticated)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  add: (cartId = 'new', productId, quantity, isAuthenticated) => {
    return new Promise((resolve, reject) => {
      (new Request()).post(`/api/cart/${cartId}/add`, JSON.stringify({
        productId: productId,
        quantity: quantity
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, isAuthenticated)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  update: (cartId, orderItemId, quantity, isAuthenticated) => {
    return new Promise((resolve, reject) => {
      (new Request()).put(`/api/cart/${cartId}/item/${orderItemId}/update`, JSON.stringify({
        quantity: quantity
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, isAuthenticated)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  updateAddress: (cartId, addressId, type) => {
    return new Promise((resolve, reject) => {
      (new Request()).put(`/api/cart/${cartId}/address/${type}/update`, JSON.stringify({
        addressId: addressId
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  checkout: (cartId, method) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/cart/${cartId}/payment_method/${method}/checkout`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  delete: (cartId, orderItemId, isAuthenticated) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete(`/api/cart/${cartId}/item/${orderItemId}/delete`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, isAuthenticated)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Cart;
