const RegisterFormState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobile: '',
  password: '',
  confirm_password: '',
  confirm: false,
  marketing: false,
};

export default RegisterFormState;
