class App {
  version = 2.5;
  menu = [];
  cartCount = 0;
  dialogState = {
    login: false,
    register: false,
    recover: false,
  };
  toastState = {
    newNotification: null,
    newMessage: null,
  };
  bankHolidays = [];
  recentlyViewedProducts = [];

  setBankHolidays = (bankHolidays) => {
    this.bankHolidays = bankHolidays;
  }

  setMenu = (categories) => {
    this.menu = categories;
  }

  setCartCount = (count) => {
    this.cartCount = count;
  }

  setToastState = (key, state) => {
    this.toastState[key] = state;
  }

  setDialogState = (key, state) => {
    this.dialogState[key] = state;
  }

  addRecentlyViewedProducts = (product) => {
    // Keep array to 6 items only
    if (5 < this.recentlyViewedProducts.length) {
      this.recentlyViewedProducts.shift();
    }

    this.recentlyViewedProducts.push(product);
  }

  removeRecentlyViewedProduct = (index) => {
    this.recentlyViewedProducts.splice(index, 1);
  };
}

export default App;
