import Account from './api/Account';
import Cart from './api/Cart';
import Order from './api/Order';
import Category from './api/Category';
import Gateway from './api/Gateway';
import Product from './api/Product';
import Notify from './api/Notify';
import Search from './api/Search';
import Wishlist from './api/Wishlist';
import GovUK from './api/GovUK';

const Api = {
  Account: Account,
  Order: Order,
  Cart: Cart,
  Category: Category,
  Gateway: Gateway,
  Product: Product,
  Notify: Notify,
  Search: Search,
  Wishlist: Wishlist,
  GovUK: GovUK
};

export default Api;
