class Order {
  cartId = 'new';
  version = 1.2;
  items = [];
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  mobile = '';
  paymentFirstName = '';
  paymentLastName = '';
  paymentCompany = '';
  paymentAddressLine1 = '';
  paymentAddressLine2 = '';
  paymentAddressLine3 = '';
  paymentCityTown = '';
  paymentCounty = '';
  paymentPostCode = '';
  paymentCountry = '';
  paymentMethod = '';
  shippingFirstName = '';
  shippingLastName = '';
  shippingCompany = '';
  shippingAddressLine1 = '';
  shippingAddressLine2 = '';
  shippingAddressLine3 = '';
  shippingCityTown = '';
  shippingCounty = '';
  shippingPostCode = '';
  shippingCountry = '';
  shippingMethod = 'Flat Delivery Rate';
  comment = '';
  tracking = '';
  itemsTotal = 0;
  shippingTotal = 499;
  subTotal = 0;
  tax = 0;
  total = 0;
  status = 'new';
}

export default Order;
