import Request from '../../system/Request';

const Address = {
  get: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/addresses/all', {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  new: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).post(`/api/addresses/new`, JSON.stringify(data), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  update: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).put(`/api/addresses/${data.id}/update`, JSON.stringify(data), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  delete: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).delete(`/api/addresses/${data.id}/delete`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Address;
