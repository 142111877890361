import React from 'react';
import FontAwesomeIcon from '../../../../FontAwesomeIcon';

import styles from './styles.module.scss';

const TextField = ({
  id,
  type = 'text',
  value,
  placeholder,
  charLimit = 0,
  onChange,
  readOnly = false,
  disabled = false,
  required,
  autoComplete = 'on',
  error = false,
  pattern = undefined,
  className = '',
  onSubmit = () => {}
}) => {
  return <div className={`${styles.container} ${className}`}>
    <div className={'d-flex flex-row'}>
      <div className={`d-flex flex-column flex-fill ${error || (0 < charLimit && charLimit < value.toString().length) ? styles.hasError : ''}`}>
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(id, e.target.value)}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          pattern={pattern}
          onKeyUp={(e) => e.key === 'Enter' && onSubmit()}
        />
        {charLimit ? <div className={`${styles.charLimit} text-dark`}>
          Max Character Limit {value.toString().length}/{charLimit}
        </div> : ''}
      </div>
      <div className={'d-flex flex-column w-25px p-0 justify-content-center align-items-center'}>
        {(required && 0 === value.toString().length) || (0 < charLimit && charLimit < value.toString().length) ? <FontAwesomeIcon
          icon={'times'}
          className={'text-dark'}
        /> : required || 0 < value.toString().length ? <FontAwesomeIcon
          icon={!error && 0 < value.toString().length ? 'check' : 'times'}
          className={`${!error && 0 < value.toString().length ? 'text-success' : 'text-danger'}`}
        /> : ''}
      </div>
    </div>
  </div>;
};

export default TextField;
