class CryptoService {
  type = 'RSA-OAEP';
  sha = 'SHA-256';
  mode = 'AES-GCM';
  length = 256;
  ivLength = 12;

  privateKeyType = 'PBKDF2';
  privateKeyLength = 2048;
  dataLength = (
    this.privateKeyLength === 1024 ? 40 :
      this.privateKeyLength === 2048 ? 90 :
        this.privateKeyLength === 4096 ? 220 : 0
  );

  interations = 1000;

  salt;
  passphrase;

  chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  lookup = new Uint8Array(256);

  constructor() {
    for (let i = 0; i < this.chars.length; i++) {
      this.lookup[this.chars.charCodeAt(i)] = i;
    }
  }

  md5(inputString) {
    var hc="0123456789abcdef";
    function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
    function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
    function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
    function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
    function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
    function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
    function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
    function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
    function sb(x) {
      var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
      for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
      blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
    }
    var i,x=sb(""+inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
    for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
      a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
      b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
      c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
      d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
      a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
      b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
      c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
      d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
      a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
      b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
      c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
      d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
      a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
      b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
      c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
      d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
      a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
      b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
      c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
      d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
      a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
      b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
    }
    return rh(a)+rh(b)+rh(c)+rh(d);
  }

  arrayBufferToString(arrayBuffer) {
    return String.fromCharCode.apply(null, new Uint16Array(arrayBuffer));
  }

  stringToArrayBuffer(string) {
    const buf = new ArrayBuffer(string.length * 2);
    const bufView = new Uint16Array(buf);

    for (let i = 0, strLen = string.length; i < strLen; i++) {
      bufView[i] = string.charCodeAt(i);
    }

    return buf;
  }

  hex(buff) {
    return [].map.call(new Uint8Array(buff), b => ('00' + b.toString(16)).slice(-2)).join('');
  }

  encode64ArrayBuffer(arrayBuffer) {
    let base64 = '';

    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.length;

    for (let i = 0, l = len; i < l; i += 3) {
      base64 += this.chars[bytes[i] >> 2];
      base64 += this.chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
      base64 += this.chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
      base64 += this.chars[bytes[i + 2] & 63];
    }

    if ((len % 3) === 2) {
      base64 = base64.substring(0, base64.length - 1) + '=';
    } else if (len % 3 === 1) {
      base64 = base64.substring(0, base64.length - 2) + '==';
    }

    return base64;
  }

  decode64ArrayBuffer(base64) {
    const len = base64.length;
    let p = 0;

    let encoded1;
    let encoded2;
    let encoded3;
    let encoded4;
    let bufferLength = base64.length * 0.75;

    if (base64[base64.length - 1] === '=') {
      bufferLength--;

      if (base64[base64.length - 2] === '=') {
        bufferLength--;
      }
    }

    const arrayBuffer = new ArrayBuffer(bufferLength);
    const bytes = new Uint8Array(arrayBuffer);

    for (let i = 0; i < len; i += 4) {
      encoded1 = this.lookup[base64.charCodeAt(i)];
      encoded2 = this.lookup[base64.charCodeAt(i + 1)];
      encoded3 = this.lookup[base64.charCodeAt(i + 2)];
      encoded4 = this.lookup[base64.charCodeAt(i + 3)];

      bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
      bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
      bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
    }

    return arrayBuffer;
  }

  hash(algo, str) {
    return window.crypto.subtle.digest(algo, this.stringToArrayBuffer(str));
  }

  setSalt(salt) {
    this.salt = btoa(salt);
    return this;
  }

  getSalt() {
    return this.salt;
  }

  setPassphrase(passphrase) {
    return new Promise((resolve, reject) => {
      this.hash(this.sha, btoa(passphrase)).then((hashed) => {
        this.passphrase = this.encode64ArrayBuffer(hashed);

        resolve();
      }, (error) => {
        console.log(error);
        reject('Failed to set passphrase');
      });
    });
  }

  getPassphrase() {
    return this.passphrase;
  }

  encryptKey(privateKey) {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.importKey(
        'raw',
        this.stringToArrayBuffer(this.getPassphrase()),
        this.privateKeyType,
        false,
        ['deriveBits', 'deriveKey']
      ).then((key) => {
        return window.crypto.subtle.deriveKey({
          name: this.privateKeyType,
          hash: this.sha,
          salt: this.stringToArrayBuffer(this.getSalt()),
          iterations: this.interations
        }, key, {
          name: this.mode,
          length: this.length
        }, false, ['encrypt', 'decrypt']);
      }).then((webKey) => {
        const algoIv = window.crypto.getRandomValues(new Uint8Array(this.ivLength));

        window.crypto.subtle.encrypt({
            name: this.mode,
            length: this.length,
            iv: algoIv
          },
          webKey,
          this.stringToArrayBuffer(JSON.stringify(privateKey))
        ).then((cipherText) => {
          resolve({
            cipherText: this.encode64ArrayBuffer(cipherText),
            iv: algoIv
          });
        }, (error) => {
          console.log(error);
          reject('Encrypt Private Key Failed');
        });
      }, (error) => {
        console.log(error);
        reject('Encrypt Private Key Failed');
      });
    });
  }

  decryptKey(data) {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.importKey(
        'raw',
        this.stringToArrayBuffer(this.getPassphrase()),
        this.privateKeyType,
        false,
        ['deriveBits', 'deriveKey']
      ).then((key) => {
        return window.crypto.subtle.deriveKey({
          name: this.privateKeyType,
          hash: this.sha,
          salt: this.stringToArrayBuffer(this.getSalt()),
          iterations: this.interations
        }, key, {
          name: this.mode,
          length: this.length
        }, false, ['encrypt', 'decrypt']);
      }).then((webKey) => {
        window.crypto.subtle.decrypt(
          {
            name: this.mode,
            length: this.length,
            iv: new Uint8Array(Object.keys(data.iv).map((key) => {
              return data.iv[key];
            }))
          },
          webKey,
          this.decode64ArrayBuffer(data.cipherText)
        ).then((decrypted) => {
          resolve(JSON.parse(this.arrayBufferToString(decrypted)));
        }, (error) => {
          console.log(error);
          reject('Decrypt Private Key Failed');
        });
      }, (error) => {
        console.log(error);
        reject('Decrypt Private Key Failed');
      });
    });
  }

  generateKey() {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.generateKey(
        {
          name: this.type,
          modulusLength: this.privateKeyLength,
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: {
            name: this.sha
          },
        },
        true,
        ['encrypt', 'decrypt']
      ).then((key) => {
        resolve(key);
      }, (error) => {
        console.log(error);
        reject('Generate Key Pair Failed');
      });
    });
  }

  base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  base64url_encode(buffer) {
    return btoa(Array.from(new Uint8Array(buffer), b => String.fromCharCode(b)).join(''))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  importKey(type, key, encrypt = false) {
    return new Promise((resolve, reject) => {
      if (typeof(encrypt) === 'undefined') {
        encrypt = true;
      }

      if (type === 'private' && encrypt) {
        this.decryptKey(key).then((decryptedKey) => {
          this.importKey('private-decrypted', decryptedKey).then((importedKey) => {
            resolve(importedKey);
          }, (error) => {
            reject(error);
          });
        }, (error) => {
          reject(error);
        });
      } else {
        if (key.n && type === 'public') {
          key.n = this.base64url_encode(this.decode64ArrayBuffer(key.n));
        }

        window.crypto.subtle.importKey(
          'jwk',
          key,
          {
            name: this.type,
            hash: {
              name: this.sha
            },
          },
          true,
          [type === 'public' ? 'encrypt' : 'decrypt']
        ).then((importedKey) => {
          resolve(importedKey);
        }, (error) => {
          console.log(error);
          reject('Import ' + (type === 'private' ? 'Private' : 'Public') + ' Key Failed');
        });
      }
    });
  }

  exportKey(type, key) {
    return new Promise((resolve, reject) => {
      window.crypto.subtle.exportKey(
        'jwk',
        key
      ).then((data) => {
        if (type === 'private') {
          this.encryptKey(data).then((encrypted) => {
            resolve(encrypted);
          }, (error) => {
            reject(error);
          });
        } else {
          resolve(data);
        }
      }, (error) => {
        console.log(error);
        reject('Export ' + (type === 'private' ? 'Private' : 'Public') + ' Key Failed');
      });
    });
  }

  encryptData(data, publicKey) {
    const dataToEncrypt = (typeof(data) !== 'string' ? JSON.stringify(data) : data);

    return new Promise((resolve, reject) => {
      // Encrypt in chunks if data exceeds dataLength
      if (dataToEncrypt.length > this.dataLength) {
        const chunks = dataToEncrypt.match(new RegExp('.{1,' + this.dataLength + '}', 'g'));

        const encryptedArr = [];

        let idx = 0;
        const nextChunk = () => {
          if (idx < chunks.length) {
            this.encryptData(chunks[idx], publicKey).then((encryptedChunk) => {
              encryptedArr.push(encryptedChunk);

              idx++;
              nextChunk();
            }).catch((error) => {
              console.log(error);
              reject('Failed to Encrypt Data Chunk');
            });
          } else {
            resolve(encryptedArr);
          }
        };

        nextChunk();
      } else {
        window.crypto.subtle.encrypt(
          {
            name: this.type,
          },
          publicKey,
          this.stringToArrayBuffer(dataToEncrypt)
        ).then((encrypted) => {
          resolve(this.encode64ArrayBuffer(encrypted));
        }, (error) => {
          console.log(error);
          reject('Failed to Encrypt Data');
        });
      }
    });
  }

  decryptData(data, privateKey) {
    return new Promise((resolve, reject) => {
      if (typeof(data) !== 'string') {
        let decryptedString = '';

        let idx = 0;
        const nextChunk = () => {
          if (idx < data.length) {
            this.decryptData(data[idx], privateKey).then((decryptedChunk) => {
              decryptedString += decryptedChunk;

              idx++;
              nextChunk();
            }, (error) => {
              console.log(error);
              reject('Failed to Decrypt Data Chunk');
            });
          } else {
            resolve(decryptedString);
          }
        };

        nextChunk();
      } else {
        window.crypto.subtle.decrypt(
          {
            name: this.type,
          },
          privateKey,
          this.decode64ArrayBuffer(data)
        ).then((decrypted) => {
          resolve(this.arrayBufferToString(decrypted));
        }, (error) => {
          console.log(error);
          reject('Failed to Decrypt Data');
        });
      }
    });
  }
}

export default CryptoService;
