import Request from '../system/Request';

const Order = {
  all: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/orders/all`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  get: (orderId) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/orders/${orderId}/get`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  createPayPalOrder: (orderId) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/gateway/paypal/${orderId}/new`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  check: (orderId) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/orders/${orderId}/check`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Order;
