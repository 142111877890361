import React from 'react';
import FontAwesomeIcon from '../../../FontAwesomeIcon';
import TextField from '../_Types/TextField';

const PhoneField = ({ data, updateField, errorFields, onSubmit = () => {} }) => {
  return <fieldset>
    <label htmlFor="phone" className="text-primary mb-1">
      <FontAwesomeIcon icon="phone" className="me-2"/>
      Phone Number
    </label>
    <TextField
      id={'phone'}
      type={'tel'}
      placeholder={'Enter your phone number'}
      value={data.phone ?? ''}
      onChange={updateField}
      required={false}
      autoComplete={'off'}
      error={0 < errorFields.filter(field => 'phone' === field.key).length}
      onSubmit={onSubmit}
    />
    {0 < errorFields.filter(field => 'phone' === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => 'phone' === field.key)[0].message}
    </div> : ''}
  </fieldset>;
};

export default PhoneField;
