const MetaData = (path) => {
  const data = {
    '/': {
      title: `Jessica Alston | Homemade and Hand Crafted in the UK`,
      description: 'Jessica Alston is a family run business that has been making and selling handmade and hand crafted products in the UK for over 5 years',
    },
    'Home': {
      title: `Jessica Alston | Homemade and Hand Crafted in the UK`,
      description: 'Jessica Alston is a family run business that has been making and selling handmade and hand crafted products in the UK for over 5 years.',
    },
    '/account': {
      title: `My Account | Jessica Alston`,
      description: 'Manage your online account, orders, addresses, payment methods, and product reviews.',
    },
    '/notifications': {
      title: `Notifications | Jessica Alston`,
      description: 'View your account notifications.',
    },
    '/about': {
      title: `About Jessica Alston`,
      description: 'Jessica Alston is a family run business that has been making and selling handmade and hand crafted products in the UK for over 5 years.',
    },
    '/contact': {
      title: `Contact Jessica Alston`,
      description: 'Get in touch with our team today with your questions about packaging supplies.',
    },
    '/legal/privacy': {
      title: `Privacy Policy | Jessica Alston`,
      description: 'Jessica Alston is committed to treating your personal information with a high degree of care. Your personal information will never be shared or sold to third parties.',
    },
    '/legal/terms': {
      title: `Terms & Conditions | Jessica Alston`,
      description: 'Read our terms & conditions to find out more about how we use your personal information.',
    },
    '/legal/cookie': {
      title: `Cookie Policy | Jessica Alston`,
      description: 'When visiting our website, cookies are stored on your computer. Cookies are small text files that are stored by your browser on your device to save certain information.',
    },
    '/search': {
      title: `Search | Jessica Alston`,
      description: 'Search our website to find all of your packaging supplies / materials.',
    },
    '/login': {
      title: `Login | Jessica Alston`,
      description: 'Log in to your Jessica Alston account.',
    },
    '/register': {
      title: `Register | Jessica Alston`,
      description: 'Create an account with Jessica Alston.',
    },
    '/feedback': {
      title: `Feedback | Jessica Alston`,
      description: 'Let us know your feedback, suggestions or ideas so we can improve our website to give you a better experience.',
    },
  };

  return data[path];
};

export default MetaData;
