import React, { useRef, useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/App';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesomeIcon';
import EmailField from '../Form/Fields/EmailField';
import PasswordField from '../Form/Fields/PasswordField';
import FirstNameField from '../Form/Fields/FirstNameField';
import LastNameField from '../Form/Fields/LastNameField';
import PhoneField from '../Form/Fields/PhoneField';
import MobileField from '../Form/Fields/MobileField';
import RegisterFormState from '../../data/form/RegisterFormState';
import Api from '../../Api';

const Register = () => {
  const { appState, dispatchApp } = useAppContext();
  const [loading, setLoading] = useState(false);
  const hasLoaded = useRef(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState(RegisterFormState);
  const [errorFields, setErrorFields] = useState([]);

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;
  }, []);

  const openLogin = () => {
    appState.setDialogState('login', true);
    appState.setDialogState('register', false);

    dispatchApp({ ...appState });
  };

  const updateFieldById = (id, value) => {
    const newData = { ...data };
    newData[id] = value;

    setData(newData);
    setErrorFields([]);
  };

  const submit = () => {
    setLoading(true);
    Api.Account.register(data).then((response) => {
      setLoading(false);

      if (undefined === response.violations) {
        setSuccessMessage(<>
          <div className={'mb-2 bold'}>Account created successfully!</div>
          Please <span className={'bold text-decoration-underline'}>activate</span> your account by clicking the link in the email we sent you.
          If you can't see the email, please check your spam / junk folders.
        </>);
      } else {
        response.violations.forEach((violation) => {
          if ('This value should not be blank.' === violation.message) return;

          setErrorFields([{
            key: violation.propertyPath,
            message: 'This value is already used.' === violation.message ? 'Error registering account' : violation.message
          }]);
        })
      }
    }).catch((e) => {
      setLoading(false);
      if (e?.body?.fields) {
        let newErrorFields = [];
        e.body.fields.forEach((field) => {
          newErrorFields.push({
            key: field.key,
            message: field.message
          });
        });
        setErrorFields(newErrorFields);
      } else {
        setErrorFields([{
          key: 'register',
          message: 'An error occurred, please try again!'
        }])
      }
    });
  };

  return <form id={'register_dialog'} onSubmit={(e) => e.preventDefault()} autoComplete={'off'}>
    {successMessage ? <Row>
      <Col className={'mt-3'}>
        <Alert variant={'success'}>
          {successMessage}
        </Alert>
      </Col>
    </Row> : <Row>
    {0 < errorFields.length && 0 < errorFields.filter(field => ['register'].includes(field.key)).length ? <Col xs={12} className="mt-3 mb-3">
      <Alert variant="danger" className="p-2 m-0">
        <strong className="bold me-3">
          <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1" />
          Error
        </strong>
        {errorFields.filter(field => 'register' === field.key)[0].message}
      </Alert>
    </Col> : ''}
      <Col xs={12} md={6} className="mt-3">
        <FirstNameField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <LastNameField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} className="mt-3">
        <EmailField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <PhoneField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <MobileField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <PasswordField
          value={data.password}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <PasswordField
          id={'confirm_password'}
          label={'Confirm Password'}
          placeholder={'Confirm your password'}
          value={data.confirm_password}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} className="mt-3">
        <div className={'d-flex'}>
          <Form.Check
            type="switch"
            id="confirm"
            defaultChecked={data.confirm}
            onChange={(e) => updateFieldById('confirm', e.target.checked)}
            className={'text-primary m-0 py-0'}
          />
          <label htmlFor="confirm" className={'d-flex align-items-center'}>
            <span className={'text-primary bolder'}>Accept Terms &amp; Conditions</span>
          </label>
        </div>
        <p className={'text-dark fs-9 mb-1'}>
          Please click the checkbox above to confirm that you agree to our <Link to={'/legal/terms'}>terms &
          conditions</Link>.
        </p>
        {0 < errorFields.filter(field => 'confirm' === field.key).length ? <div className={'fs-8 red-text'}>
          <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
          {errorFields.filter(field => 'confirm' === field.key)[0].message}
        </div> : ''}
      </Col>
      <Col xs={12} className="mt-3">
        <div className={'d-flex'}>
          <Form.Check
            type="switch"
            id="marketing"
            defaultChecked={data.marketing}
            onChange={(e) => updateFieldById('marketing', e.target.checked)}
            className={'text-primary m-0 py-0'}
          />
          <label htmlFor="marketing" className={'d-flex align-items-center'}>
            <span className={'text-primary bolder'}>Receive newsletters and promotional emails</span>
          </label>
        </div>
        <p className={'text-dark fs-9 mb-1'}>
          You can unsubscribe from our newsletters and promotions at any time from your account area or by clicking the unsubscribe link in any emails.
        </p>
        {0 < errorFields.filter(field => 'marketing' === field.key).length ? <div className={'fs-8 red-text'}>
          <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
          {errorFields.filter(field => 'marketing' === field.key)[0].message}
        </div> : ''}
      </Col>
      <Col xs={12} className="mt-3">
        <Row>
          <Col>
            <Button type={'submit'} onClick={submit} disabled={loading} variant="success" className="w-100">
              <FontAwesomeIcon icon={loading ? 'spinner' : 'check'} className="me-2"/>
              {loading ? 'Loading...' : 'Create account!'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="fs-7 mt-2 text-dark text-center">
            Already have an account?
            <button type={'button'} onClick={openLogin} className="ms-2 transparent border-0 text-primary">
              Login
            </button>
          </Col>
        </Row>
      </Col>
    </Row>}
  </form>;
};

export default Register;
