import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Toast } from 'react-bootstrap';
import { useMediaQuery } from 'usehooks-ts';
import { useAccountContext } from '../../contexts/Account';
import { useAppContext } from '../../contexts/App';
import { useOrderContext } from '../../contexts/Order';
import Moment from 'moment';
import MainNav from '../MainNav';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Image from '../../components/Image';
import Api from '../../Api';

import styles from './styles.module.scss';

const Header = () => {
  const { orderState } = useOrderContext();
  const { accountState, dispatchAccount, isAuthenticated } = useAccountContext();
  const { appState, dispatchApp } = useAppContext();
  const navigate = useNavigate();
  const hasLoaded = useRef(false);
  const checkCartItemsInterval = useRef(null);
  const checkNotificationsInterval = useRef(null);
  const matches = useMediaQuery('(max-width: 992px)');
  const [timer, setTimer] = useState(0);
  const [cartTimer, setCartTimer] = useState(0);
  const [lastCartItem, setLastCartItem] = useState(null);
  const [useMobileMenu, setUseMobileMenu] = useState(false);

  const onCloseToast = (key) => {
    appState.setToastState(key, null);
    dispatchApp({ ...appState });
  };

  const onToastNavigate = (key, page) => {
    appState.setToastState(key, null);
    dispatchApp({ ...appState });
    navigate(page);
  }

  const onCartNavigate = (page) => {
    setCartTimer(0);
    setLastCartItem(null);
    navigate(page);
  };

  const onCloseCartToast = () => {
    setLastCartItem(null);
    setCartTimer(0);
  };

  const checkCartItems = useCallback(() => {
    if (appState.cartCount !== orderState?.items?.length) {
      // Notify when new item added
      if (appState.cartCount < orderState?.items?.length) {
        setCartTimer(1);
        setLastCartItem(orderState.items[orderState.items.length - 1]);
      }

      appState.cartCount = orderState?.items.length;

      dispatchApp({ ...appState });
    }
  }, [appState, orderState, dispatchApp]);

  const checkNotifications = useCallback(() => {
    if (!isAuthenticated) return;

    Api.Account.counts().then((counts) => {
      if (accountState.user.notifications < counts.notifications) {
        appState.toastState.newNotification = {
          count: counts.notifications - accountState.user.notifications,
          date: Moment.now()
        };
        setTimer(1);

        dispatchApp({...appState});

        const newAccountState = {...accountState};
        newAccountState.user.notifications = counts.notifications;

        dispatchAccount({...newAccountState});
      }
    });
  }, [isAuthenticated, accountState, appState, dispatchApp, dispatchAccount]);

  useEffect(() => {
    if (matches === useMobileMenu) return;

    setUseMobileMenu(matches);
  }, [matches, useMobileMenu]);

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;

    const setHeaderSpace = () => {
      if (undefined === document.getElementById('header') || 0 === document.getElementById('header').offsetHeight) return;

      document.getElementById('root').style.paddingTop = document.getElementById('header').offsetHeight + 'px';
    };

    window.addEventListener('resize', () => {
      setHeaderSpace();
    });

    setTimeout(() => {
      setHeaderSpace();
    }, 10);
  }, []);

  useEffect(() => {
    if (checkCartItemsInterval.current) clearInterval(checkCartItemsInterval.current);
    checkCartItemsInterval.current = setInterval(() => {
      checkCartItems();
    }, 2000);

    if (checkNotificationsInterval.current) clearInterval(checkNotificationsInterval.current);
    checkNotificationsInterval.current = setInterval(() => {
      checkNotifications();
    }, (1000 * 30));
  }, [checkCartItems, checkNotifications]);

  useEffect(() => {
    if (!appState.toastState['newNotification']) return;

    setTimeout(() => {
      setTimer(timer + 1);
    }, 1000);
  }, [appState, timer]);

  useEffect(() => {
    if (null === lastCartItem) return;

    setTimeout(() => {
      setCartTimer(cartTimer + 1);
    }, 1000);
  }, [lastCartItem, cartTimer]);

  return <>
    {null !== appState.toastState['newNotification'] ? <div className={styles.floating}>
      <Toast animation={true}  onClose={() => onCloseToast('newNotification')}>
        <Toast.Header>
          <Image src={'images/icon.png'} type={''} webp={false} width={20} height={20} className="rounded me-2" />
          <strong className="text-secondary bold me-auto">
            New Notification{1 < appState.toastState['newNotification']?.count ? 's' : ''}
          </strong>
          <small>
            {Moment(appState.toastState['newNotification']?.date).fromNow()}
          </small>
        </Toast.Header>
        <Toast.Body className={'d-flex'}>
          <div className={'d-flex flex-column flex-fill align-items-start justify-content-center'}>
            <div>
              You have <span className={'bolder text-tertiary'}>{appState.toastState['newNotification']?.count}</span> new notification{1 < appState.toastState['newNotification']?.count ? 's' : ''}
            </div>
          </div>
          <div className={'d-flex flex-column text-end'}>
            <Button onClick={() => onToastNavigate('newNotification', '/account/notifications')} size={'sm'} className={'pull-right'}>
              view now
              <FontAwesomeIcon icon={'chevron-right'} className={'ms-2'} />
            </Button>
          </div>
        </Toast.Body>
      </Toast>
    </div> : ''}
    {null !== lastCartItem ? <div className={styles.floating}>
      <Toast animation={true} onClose={() => onCloseCartToast()}>
        <Toast.Header>
          <FontAwesomeIcon icon={'shopping-cart'} className={'text-success me-2'} />
          <strong className="text-secondary bold me-auto">
            Shopping Cart
          </strong>
          <small>{Moment(appState.toastState['newNotification']?.date).fromNow()}</small>
        </Toast.Header>
        <Toast.Body>
          <strong className="text-success bold me-auto">
            Item added to cart
          </strong>
          <div className={'d-flex'}>
            <div className={'d-flex flex-column align-items-start justify-content-start pt-1'}>
              <Badge className={'me-2'}>
                {lastCartItem.quantity}x
              </Badge>
            </div>
            <div className={'d-flex flex-column flex-fill align-items-start justify-content-center'}>
              {lastCartItem.name}
            </div>
          </div>
          <div className={'d-flex mt-2 justify-content-end'}>
            <div className={'d-flex flex-fill flex-column text-end'}>
              <div>
                <Button
                  variant={'success'}
                  onClick={() => onCartNavigate('/cart')}
                  size={'sm'}
                  className={'pull-right text-white'}
                >
                  view cart
                  <FontAwesomeIcon icon={'chevron-right'} className={'ms-2'}/>
                </Button>
                <Button
                  variant={'outline-primary'}
                  onClick={() => onCartNavigate('/cart')}
                  size={'sm'}
                  className={'pull-right me-2'}
                >
                  checkout
                  <FontAwesomeIcon icon={'chevron-right'} className={'ms-2'}/>
                </Button>
              </div>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </div> : ''}
    <MainNav/>
  </>;
};

export default Header;
