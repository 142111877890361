import Cache from '../system/Cache';

class Request {
  account = null;

  constructor() {
    this.cache = new Cache();
    this.account = this.cache.get('account');
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_HOST_API + '/api/token/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: this.account.refresh_token
        })
      })
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((e) => reject(e));
    });
  }

  getDefaultHeaders(headers, auth, token = null) {
    if (auth &&
      null !== this.account &&
      (null !== token || null !== this.account?.token)
    ) {
      headers.Authorization = ['Bearer', token ?? this.account.token].join(' ');
    }

    return headers;
  }

  checkResponse(response) {
    return new Promise((resolve, reject) => {
      if (401 === response.status) {
        switch (response.body.message) {
          case 'Your token is expired, please renew it.':
          case 'Expired JWT Token':
            this.refreshToken()
              .then((refreshTokenResponse) => {
                if (refreshTokenResponse.token && refreshTokenResponse.refresh_token) {
                  this.account.token = refreshTokenResponse.token;
                  this.account.refresh_token = refreshTokenResponse.refresh_token;
                  this.cache.set('account', this.account);
                }
                reject({
                  token: refreshTokenResponse.token
                });
              }).catch((e) => {
                if (this.account.token) {
                  this.cache.remove('account');
                  window.location.reload();
                }
              });
            break;
          default:
            if (this.account.token) {
              this.cache.remove('account');
              window.location.reload();
            }
            break;
        }
      } else if (response.status >= 200 && response.status < 300) {
        resolve(response.body);
      } else {
        reject(response);
      }
    });
  }

  fetch(path, headers = {}, body, method = 'GET') {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_HOST_API + path, {
        method: method,
        headers: headers,
        body: body,
        mode: 'cors',
      })
        .then(response => 'application/json' === headers.Accept ? response.json().then((body) => {
          resolve({
            status: response.status,
            body: body
          });
        }) : response)
        .then(response => resolve(response))
        .catch(e => reject(e));
    });
  }

  post(path, body, headers = {}, auth = false) {
    return new Promise((resolve, reject) => {
      this.fetch(path, this.getDefaultHeaders(headers, auth), body, 'POST')
        .then((response) => {
          this.checkResponse(response)
            .then(response => resolve(response))
            .catch(retryLastRequest => {
              if (retryLastRequest.token) {
                this.post(path, body, this.getDefaultHeaders(headers, auth, retryLastRequest.token), auth)
                  .then(response => resolve(response))
                  .catch(e => reject(e));
              } else {
                reject(retryLastRequest);
              }
            });
        }).catch(e => reject(e));
    });
  }

  put(path, body, headers = {}, auth = false) {
    return new Promise((resolve, reject) => {
      this.fetch(path, this.getDefaultHeaders(headers, auth), body, 'PUT')
        .then((response) => {
          this.checkResponse(response)
            .then(response => resolve(response))
            .catch(retryLastRequest => {
              if (retryLastRequest.token) {
                this.put(path, body, this.getDefaultHeaders(headers, auth, retryLastRequest.token), auth)
                  .then(response => resolve(response))
                  .catch(e => reject(e));
              } else {
                reject(retryLastRequest);
              }
            });
        }).catch(e => reject(e));
    });
  }

  delete(path, headers = {}, auth = false) {
    return new Promise((resolve, reject) => {
      this.fetch(path, this.getDefaultHeaders(headers, auth), null, 'DELETE')
        .then((response) => {
          this.checkResponse(response)
            .then(response => resolve(response))
            .catch(retryLastRequest => {
              if (retryLastRequest.token) {
                this.delete(path, this.getDefaultHeaders(headers, auth, retryLastRequest.token), auth)
                  .then(response => resolve(response))
                  .catch(e => reject(e));
              } else {
                reject(retryLastRequest);
              }
            });
        }).catch(e => reject(e));
    });
  }

  get(path, headers = {}, auth = false) {
    return new Promise((resolve, reject) => {
      this.fetch(path, this.getDefaultHeaders(headers, auth))
        .then((response) => {
          this.checkResponse(response)
            .then(response => resolve(response))
            .catch(retryLastRequest => {
              if (retryLastRequest.token) {
                this.get(path, this.getDefaultHeaders(headers, auth, retryLastRequest.token), auth)
                  .then(response => resolve(response))
                  .catch(e => reject(e));
              } else {
                reject(retryLastRequest);
              }
            });
        }).catch(e => reject(e));
    });
  }
}

export default Request;
