import React, { createContext, useContext, useState } from 'react';
import Cache from '../system/Cache';
import StateNormalizer from '../system/StateNormalizer';
import OrderClass from '../class/Order.class';
import Api from "../Api";

export const OrderContext = createContext({
  state: new OrderClass(),
  dispatch: () => null,
  updateOrderFromApi: () => null,
});

export const OrderProvider = ({ children }) => {
  const cache = new Cache();
  const defaultOrder = new OrderClass();
  const stateNormalizer = new StateNormalizer();

  const normalizeData = (key, classObj) => {
    // Get data from cache
    const dataFromCache = cache.get(`wholesale_packaging__${key}`);
    if (dataFromCache) {
      // Normalize data into class
      return stateNormalizer.normalize(classObj, dataFromCache);
    }
    return classObj;
  };

  const [orderState, setOrderState] = useState(() => {
    const localOrderState = cache.get('wholesale_packaging__order');

    if (null === localOrderState) {
      return defaultOrder;
    }

    const order = normalizeData('order', new OrderClass());
    if (defaultOrder.version !== order.version) {
      return defaultOrder;
    }

    return order;
  });

  const dispatchOrder = (orderState) => {
    // Clear keys that are not needed to be cached for order
    cache.set('wholesale_packaging__order', { ...orderState });

    setOrderState(orderState);
  };

  const updateOrderFromApi = (isAuthenticated, cartId = null, callback) => {
    if ('new' === orderState.cartId && null === cartId) return;

    if (null === cartId || 'new' === cartId) {
      cartId = orderState.cartId;
    }

    Api.Cart.get(cartId, isAuthenticated).then((response) => {
      const data = response.data;
      data.cartId = data.id;

      const normalizedOrder = stateNormalizer.normalize(orderState, response.data);

      dispatchOrder({ ...normalizedOrder });

      if (callback) {
        callback();
      }
    }).catch((e) => {
      console.log(e);
      if (callback) {
        callback();
      }
    });
  };

  return <OrderContext.Provider value={{ orderState, dispatchOrder, updateOrderFromApi }}>
    { children }
  </OrderContext.Provider>;
};

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useOrderContext must be used within an OrderProvider');
  }

  return context;
};
