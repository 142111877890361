import React, { useState } from 'react';
import { Alert, Badge, Button, Col, Row } from 'react-bootstrap';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Dialog from '../';
import Api from '../../Api';

import styles from './styles.module.scss';

const ForgotPasswordDialog = ({ closeDialog }) => {
  const [data, setData] = useState({
    email: '',
    new_password: '',
    confirm_new_password: ''
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorFields, setErrorFields] = useState([]);

  const validateFields = () => new Promise((resolve, reject) => {
    Promise.all(Object.keys(data).map((key) => {
      return validateField(key, data[key]).then(() => true).catch(() => false);
    })).then((result) => result.includes(false) ? reject() : resolve());
  });

  const validateField = (field, value) => new Promise((resolve, reject) => {
    switch (field) {
      case 'email':
        if (String(value).toLowerCase().match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        )) {
          resolve();
        } else {
          errorFields.push({
            key: 'email',
            message: 'Please enter a valid email address.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'password':
        if (value.length < 8) {
          errorFields.push({
            key: 'password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z0-9,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'confirm_new_password':
        if (value.length < 8) {
          errorFields.push({
            key: 'confirm_new_password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z0-9,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'confirm_new_password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (value !== data.new_password) {
          errorFields.push({
            key: 'confirm_new_password',
            message: 'Passwords do not match.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      default:
        // company can be auto validated (optional field)
        resolve();
        break;
    }
  });

  const updateField = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    setData(newData);
    setErrorFields([]);
  };

  const submit = () => {
    setLoading(true);
    validateFields().then(() => {
      Api.Account.recover(data).then((response) => {
        setLoading(false);

        if ('200 OK' === response.status) {
          setTimeout(() => {
            setErrorFields([]);
            setSuccessMessage('If you have an account with us, you should receive an email to activate your new password.');
          }, 1000);
        } else {
          setSuccessMessage(null);
          setErrorFields([{
            key: 'recover',
            message: 'Error recovering account'
          }]);
        }
      }).catch((e) => {
        setLoading(false);
        setErrorFields([{
          key: 'recover',
          message: 'An error occurred, please try again later!'
        }])
        console.log('error', e);
      });
    }).catch((e) => {
      setLoading(false);
      console.log('error in validation', e);
    });
  };

  return <Dialog title={<>
    <FontAwesomeIcon icon="lock" className="me-3" />
    Forgot Password
  </>} closeDialog={closeDialog}>
    <div className={styles.dialog}>
      {successMessage ? <Alert variant={'success'} className="p-2 ps-3 pe-3 w-100">
        <strong className="bold d-block mt-1 mb-2">
          <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1"/>
          SUCCESS
        </strong>
        <p className="mb-0 text-wrap">
          {successMessage}
        </p>
      </Alert> : 0 < errorFields.filter(field => 'recover' === field.key).length ? <Alert variant="danger" className="p-2 ps-3 pe-3 w-100">
        <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
        {errorFields.filter(field => 'recover' === field.key)[0].message}
      </Alert> : <Alert variant="warning" className="p-2 ps-3 pe-3 w-100">
        <Button
          type={'button'}
          href="https://help.purpleio.uk/account-management/forgot-password#why-will-my-data-be-lost-when-resetting-my-password"
          variant="warning"
          target="_blank"
          className="float-end p-1 fs-7"
        >
          Read more
        </Button>
        <strong className="bold d-block mt-1 mb-2">
          <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1"/>
          WARNING
        </strong>
        <p className="mb-0 text-wrap">
          All synced app data on your account will be lost.
        </p>
      </Alert>}
      <Row>
        <Col xs={12}>
          <fieldset>
            <label htmlFor="email" className="mb-1">
              <FontAwesomeIcon icon="envelope" className="me-2"/>
              Email address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={data.email}
              placeholder="i.e. me@example.com"
              onChange={updateField}
              onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
              className={errorFields.includes('email') ? styles.input__error : ''}
              autoComplete={'off'}
              required
            />
          </fieldset>
          {0 < errorFields.filter(field => 'email' === field.key).length ? <div className={'red-text'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            {errorFields.filter(field => 'email' === field.key)[0].message}
          </div> : ''}
        </Col>
        <Col xs={12} className="mt-3">
          <fieldset>
            <label htmlFor="password" className="mb-1">
              <FontAwesomeIcon icon="lock" className="me-2"/>
              Password
            </label>
            <input
              type="password"
              id="new_password"
              name="new_password"
              value={data.new_password}
              onChange={updateField}
              placeholder="Enter a new password"
              className={errorFields.includes('new_password') ? styles.input__error : ''}
              autoComplete={'off'}
              required
            />
          </fieldset>
          {0 < errorFields.filter(field => 'new_password' === field.key).length ? <div className={'red-text'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            {errorFields.filter(field => 'new_password' === field.key)[0].message}
          </div> : <div className={'fs-8'}>
            <FontAwesomeIcon icon={'info-circle'} className={'me-2'} />
            <span className={'fw-bold'}>Valid Characters:</span> Letters (A-Z), numbers (0-9) and the following characters:<br />
            <Badge bg={'dark'} className={'mt-1 fs-8'}>
              , . - _ £ $ ! @ % ^ * _ + = { } ] ] :
            </Badge>
          </div>}
        </Col>
        <Col xs={12} className="mt-3">
          <fieldset>
            <label htmlFor="confirm_new_password" className="mb-1">
              <FontAwesomeIcon icon="lock" className="me-2"/>
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirm_new_password"
              name="confirm_new_password"
              value={data.confirm_new_password}
              onChange={updateField}
              placeholder="Confirm your password"
              className={errorFields.includes('confirm_new_password') ? styles.input__error : ''}
              autoComplete={'off'}
              required
            />
          </fieldset>
          {0 < errorFields.filter(field => 'confirm_new_password' === field.key).length ? <div className={'red-text'}>
            <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
            {errorFields.filter(field => 'confirm_new_password' === field.key)[0].message}
          </div> : ''}
        </Col>
        <Col xs={12} className="mt-3">
          <Button onClick={() => submit()} disabled={loading} variant="success" className="w-100">
            <FontAwesomeIcon icon="sign-in" className="me-2"/>
            {loading ? 'Loading...' : 'Recover Account' }
          </Button>
        </Col>
      </Row>
    </div>
  </Dialog>;
}

export default ForgotPasswordDialog;
