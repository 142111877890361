import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const setHeaderSpace = () => {
      if (undefined === document.getElementById('header') || 0 === document.getElementById('header')?.offsetHeight) return;

      document.getElementById('root').style.paddingTop = document.getElementById('header')?.offsetHeight + 'px';
    };

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });

      setHeaderSpace();
    }, 100);

    // Record page change to Google Analytics
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, [pathname]);

  return null;
}
