import Request from '../../system/Request';

const PayPal = {
  createOrder: (orderId) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/gateway/paypal/${orderId}/new`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  capture: (orderId) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/gateway/paypal/${orderId}/capture`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  get: (orderId) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/gateway/paypal/${orderId}/get`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default PayPal;
